<template class="orderdetail">
  <v-card>
    <v-card-title>定期便注文履歴</v-card-title>
    <v-card-subtitle class="px-6" style="font-size:1rem;">
      <v-row>
        <v-col cols="4">
          <div>
            <span style="font-weight:bold;">注文ID: </span>
            <span>{{ SubsSchOrderProgressList.sch_id }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">注文日時: </span>
            <span>{{ SubsSchOrderProgressList.order_dt }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">決済金額: </span>
            <span>{{ sumPrice }}</span>
          </div>
        </v-col>
        <v-col cols="8">
          <div>
            <span style="font-weight:bold;">注文者: </span>
            <span>{{ SubsSchOrderProgressList.mbr_name }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">お届け先: </span>
            <span>{{ SubsSchOrderProgressList.addr_name }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">郵便番号: </span>
            <span>{{ SubsSchOrderProgressList.addr_zip }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">お届け先住所: </span>
            <span>{{ SubsSchOrderProgressList.addr_addr1 }} {{ SubsSchOrderProgressList.addr_addr2 }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-divider></v-divider>

    <v-card-title>注文詳細</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="detailHeaders"
        :items="SubsSchOrderProgressList.details"
        :items-per-page="-1"
        hide-default-footer
        style="border:thin solid rgba(0, 0, 0, 0.14)"
        class="elevation-0"
      >
        <template v-slot:[`item.order_qty`]="{ item }">
          {{ formatData(item.order_qty) }}
        </template>
        <template v-slot:[`item.order_tip`]="{ item }">
          {{ formatData(item.order_tip) }}
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              :colspan="4"
              style="background: #fafafa"
            >
              <label>合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumPrice }}</label>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-title>操作履歴</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="progressHeaders"
        :items="SubsSchOrderProgressList.progresses"
        :items-per-page="-1"
        hide-default-footer
        style="border:thin solid rgba(0, 0, 0, 0.14)"
        class="elevation-0"
      >
        <template v-slot:[`item.ssop_proc_memo`]="{ item }">
          <div class="ssop_proc_memo" :title="`${item.ssop_proc_memo}`">
            {{ item.ssop_proc_memo }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        outlined
        elevation="0"
        :to="{ name: 'scheduledsubscription-list' }"
      >
        <span>戻る</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  components: {
  },
  data: () => ({
    detailHeaders: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        width: '7%',
        value: 'detail_id',
      },
      {
        text: '定期便ード',
        align: 'left',
        sortable: false,
        width: '20%',
        value: 'subs_code',
      },
      {
        text: '定期便名',
        align: 'left',
        sortable: false,
        width: '33%',
        value: 'subs_name',
      },
      {
        text: '注文数量',
        align: 'right',
        sortable: false,
        width: '20%',
        value: 'order_qty',
      },
      {
        text: '注文時税込み価格',
        align: 'right',
        sortable: false,
        width: '20%',
        value: 'subs_tip',
      },
    ],
    progressHeaders: [
      {
        text: 'ID',
        align: 'left',
        sortable: false,
        width: '7%',
        value: 'sch_id',
      },
      {
        text: '処理日時',
        align: 'left',
        sortable: false,
        width: '20%',
        value: 'ssop_proc_dt',
      },
      {
        text: '処理メモ',
        align: 'left',
        sortable: false,
        width: '52%',
        value: 'ssop_proc_memo',
      },
      {
        text: '状態',
        align: 'left',
        sortable: false,
        width: '21%',
        value: 'subs_status_desc',
      },
    ],
  }),
  computed: {
    ...mapState('scheduledSubsStore', ['SubsSchOrderProgressList']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    sumPrice() {
      let sum = 0
      if (this.SubsSchOrderProgressList.details) {
        this.SubsSchOrderProgressList.details.forEach(a => {
          sum += a.subs_tip * a.order_qty
        })
      }

      return sum ? sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : ''
    },
  },
  watch: {
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('scheduledSubsStore', ['loadSubsSchOrderProgress']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadSubsSchOrderProgress(this.$route.params.id).then(() => {
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>

<style scoped>
.theme--light.v-data-table >>> th {
  border-top: 0;
}
.ssop_proc_memo{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 41vw;
}
</style>
